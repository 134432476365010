import React from "react"

import * as S from "./styled"

const Clientes = () => {
  return (
    <S.Clientes>
      <S.ClientesWrapper>
        <S.ClientesHeader>
          <S.ClientesHeaderTitle>Nossos Parceiros</S.ClientesHeaderTitle>
          <S.ClientesHr />
        </S.ClientesHeader>
        <S.ClientesText>
          O comprometimento com os prazos de entrega, a qualidade dos seus
          serviços, e uma política de preços altamente competitiva, fazem com
          que a Açocon seja a garantia de uma parceria bem estruturada para o
          seu projeto.
        </S.ClientesText>
        <S.ClientesText>Conheça alguns de nossos clientes:</S.ClientesText>
        <S.ClientesBox>
          <S.ClientesLista>
            <S.ClientesListaItem>
              Agretal Indústria e Comercio Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>Baspel Embalagens Ltda</S.ClientesListaItem>
            <S.ClientesListaItem>
              Bom Retiro Materiais para Construção
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Carius - Serviços Médicos e Hospitalares
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Cassiano engenharia e Projetos
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Consigma Engenharia e Construções Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Construlac Construção e Comercio Ltda – Me
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Construtora e Incorporadora Villaramos
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Forty Construtora e Engenharia Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Gecat – Projetos Construções Assessoria Técnica
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              GNR Construtora e Incorporadora Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              G.P.O – Grigoletto Projetos e Obras Ltda Epp
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Granziol Prestação de Serviços – EPP
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Hexágono Construtora, Comércio e Engenharia Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              Hydraplus Indústria e Comercio Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>Iluminadora Nalli Ltda</S.ClientesListaItem>
            <S.ClientesListaItem>Imobiliaria D’Italia</S.ClientesListaItem>
            <S.ClientesListaItem>
              Infratecnica Engenharia e Construções Ltda
            </S.ClientesListaItem>
          </S.ClientesLista>
          <S.ClientesLista>
            <S.ClientesListaItem>Justa Construtora</S.ClientesListaItem>
            <S.ClientesListaItem>Laminação Brasileira</S.ClientesListaItem>
            <S.ClientesListaItem>Laminação Curtolo</S.ClientesListaItem>
            <S.ClientesListaItem>Laminação Paulista</S.ClientesListaItem>
            <S.ClientesListaItem>Marmoraria Casagrande</S.ClientesListaItem>
            <S.ClientesListaItem>
              Metalplay Indústria e Comercio Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              MG Empreiteira e Construtora Ltda-Me
            </S.ClientesListaItem>
            <S.ClientesListaItem>MP Construções Ltda</S.ClientesListaItem>
            <S.ClientesListaItem>
              Prata Laminação de Metais Ltda Me
            </S.ClientesListaItem>
            <S.ClientesListaItem>SNP Projetos e Engenharia</S.ClientesListaItem>
            <S.ClientesListaItem>
              Steel Tech Ind. E Comercio de Microfundidos Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>Tonetto e Trova Ltda- Me</S.ClientesListaItem>
            <S.ClientesListaItem>
              VCM Construtora Comercio e Empreendimentos Imobiliários
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              VML Participações Administração e Assosoria Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              WLP Waldeonor Jose João Lourenço Pinto
            </S.ClientesListaItem>
            <S.ClientesListaItem>
              X – Turbo Provedor de Internet Ltda
            </S.ClientesListaItem>
            <S.ClientesListaItem>X-Data</S.ClientesListaItem>
            <S.ClientesListaItem>Zito Construções</S.ClientesListaItem>
          </S.ClientesLista>
        </S.ClientesBox>
      </S.ClientesWrapper>
    </S.Clientes>
  )
}

export default Clientes
