import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Clientes from "../components/Clientes"
import CallToAction from "../components/CallToAction"
import Contato from "../components/Contato"

const ClientesPage = () => {
  return (
    <Layout>
      <SEO title="Clientes" />
      <Clientes />
      <CallToAction />
      <Contato title={false} />
    </Layout>
  )
}

export default ClientesPage
